import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import imagen01 from "./imagenes/1.jpeg";
import imagen02 from "./imagenes/2.jpeg";
import imagen03 from "./imagenes/3.jpeg";
import imagen04 from "./imagenes/4.jpeg";
import imagen05 from "./imagenes/5.jpeg";
import imagen06 from "./imagenes/6.jpeg";
import imagen07 from "./imagenes/7.jpeg";
import imagen08 from "./imagenes/8.jpeg";
import imagen09 from "./imagenes/9.jpeg";
import imagen10 from "./imagenes/10.jpeg";
import imagen11 from "./imagenes/11.jpeg";
import imagen12 from "./imagenes/12.jpeg";
import imagen13 from "./imagenes/13.jpeg";
import imagen14 from "./imagenes/14.jpeg";

class App extends Component {
  arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "50%",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  render() {
    return (
      <Carousel
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...this.arrowStyles, left: 15 }}
            >
              {"<"}
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{ ...this.arrowStyles, right: 15 }}
            >
              {">"}
            </button>
          )
        }
        showThumbs={false}
        showIndicators={false}
      >
        <img src={imagen01} />
        <img src={imagen02} />
        <img src={imagen03} />
        <img src={imagen04} />
        <img src={imagen05} />
        <img src={imagen06} />
        <img src={imagen07} />
        <img src={imagen08} />
        <img src={imagen09} />
        <img src={imagen10} />
        <img src={imagen11} />
        <img src={imagen12} />
        <img src={imagen13} />
        <img src={imagen14} />
      </Carousel>
    );
  }
}

export default App;
